import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Faqs from '../components/faqs';

// services
import { trackEvent } from '../services/tracking/tracking';

import styles from './contact.module.scss';

const ContactUsPage = () => {
  const staticData = useStaticQuery(graphql`
    query ContactUs {
      directusContactUs {
        page_title
        page_description
        hero_title
        hero_description
        faqs_section_title
        faq_categories {
          name
          id
          questions {
            id
            question
            answer
          }
        }
        contact_section_title
        email
        email_label
        phone
        phone_label
        social_media_label
      }
      directusSetting {
        social_links {
          icon
          url
        }
      }
    }
  `);

  const { directusContactUs, directusSetting } = staticData;

  return (
    <Layout>
      <SEO title={directusContactUs.page_title} description={directusContactUs.page_description} />
      <section className={styles.sectionWhite}>
        <div className={styles.container}>
          <h1>{directusContactUs.hero_title}</h1>
          <hr className={styles.hr} />
          <div className={styles.row}>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: directusContactUs.hero_description,
              }}
            ></div>
          </div>
        </div>
      </section>

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.faqsWrapper}>
              <h1>{directusContactUs.faqs_section_title}</h1>
              <hr className={styles.hrLeftAligned} />
              <Faqs faqs={directusContactUs.faq_categories} />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionContactDetails}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contactWrapper}>
              <h1>{directusContactUs.contact_section_title}</h1>
              <hr className={styles.hrLeftAligned} />

              <div className={styles.contactDetails}>
                <div className={styles.email}>
                  <p>
                    <a
                      href={'mailto:' + directusContactUs.email}
                      onClick={() => {
                        // tracking
                        trackEvent('Click CTA', {
                          cta_name: directusContactUs.email,
                          cta_category: 'user engagement',
                        });
                      }}
                    >
                      {directusContactUs.email}
                    </a>
                    <br />
                    {directusContactUs.email_label}
                  </p>
                </div>
                {/* <div className={styles.phone}>
                  <p>
                    <a
                      href={'tel:' + directusContactUs.phone}
                      onClick={() => {
                        // tracking
                        trackEvent('Click CTA', {
                          cta_name: directusContactUs.phone,
                          cta_category: 'user engagement',
                        });
                      }}
                    >
                      {directusContactUs.phone}
                    </a>
                    <br />
                    {directusContactUs.phone_label}
                  </p>
                </div> */}
                <div className={styles.chat}>
                  <p>
                    <a
                      onClick={() => {
                        // tracking
                        trackEvent('Click CTA', {
                          cta_name: 'chat',
                          cta_category: 'user engagement',
                        });
                        Intercom('show');
                      }}
                    >
                      Chat with us
                    </a>
                    <br />
                    Chat to ask us anything at all
                  </p>
                </div>
                <div className={styles.social}>
                  <ul className={styles.socialItems}>
                    {directusSetting.social_links.map((item: any) => (
                      <li key={item.url} className={styles.socialItem}>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            // tracking
                            trackEvent('Click CTA', {
                              cta_name: item.icon,
                              cta_category: 'social links',
                            });
                          }}
                        >
                          <img src={`/images/icon_${item.icon}_dark.svg`} alt={item.icon} />
                        </a>
                      </li>
                    ))}
                  </ul>
                  <p>{directusContactUs.social_media_label}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactUsPage;

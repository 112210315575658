import React, { useState } from 'react';
import Accordion from '../../components/accordion';
// styles
import styles from './faqs.module.scss';

interface IProps {
  faqs: any;
}

const Faqs: React.FC<IProps> = ({ faqs }) => {
  const defaultSelectedCategory = faqs[0].id;
  const [selectedCategory, setSelectedCategory] = useState(defaultSelectedCategory);

  const getSelectedCategoryQuestions = () => {
    const category = faqs.find((faq: any) => faq.id === selectedCategory);
    return category.questions;
  };

  const selectedCategoryQuestions = getSelectedCategoryQuestions();

  return (
    <div className={styles.faqsContainer}>
      <div className={styles.categories}>
        {faqs.map(({ id, name }: { id: string; name: string }) => (
          <div
            key={id}
            className={selectedCategory === id ? styles.selectedCategory : styles.category}
            onClick={() => {
              setSelectedCategory(id);
            }}
          >
            {name}
          </div>
        ))}
      </div>
      <Accordion items={selectedCategoryQuestions} isHtmlContent />
    </div>
  );
};

export default Faqs;
